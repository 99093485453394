import React from 'react';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';
import Markdown from 'markdown-to-jsx';
import Layout from '../../components/layout';
import ImageGallery from '../../components/page/ImageGallery';
import Bullet from '../../components/page/bullet';


export default function Artiste({ data, pageContext }) {
  const {
    nom,
    discipline,
    description,
    a_propos,
    image_presentation,
    site_web,
    images,
  } = data.allStrapiArtistes.nodes[0]; // because of the filter on id, there is always jsut one node
  const h1Style = {
    fontSize: '1.5em',
  };
  const h2Style = {
    fontSize: '1.1em',
  };

  const color = '#d3dce9';
  const cStyle = {
    boxShadow: '1px 3px 2px #888888',
    textAlign: 'justify',
    backgroundColor: color,
  };
  const descStyle = {
    backgroundColor: color,
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.5rem',
    fontSize: '1rem',
  };

  try {
    return (
      <Layout title="title" buildTime={pageContext.buildTime}>
        <div className="row">
          <div className="col-md-8 col-md-pull-4 blog-main ">
            <div className="d-flex justify-content-start mb-3 align-items-center ">
              <div className=" card-text pl-1 pageClassic">
                {' '}
                <h1 style={h1Style} className=" card-text pageClassic">
                  {nom}
                  {' '}
                  -
                  {' '}
                  {discipline}
                </h1>
              </div>
            </div>

            <div className="d-flex justify-content-start mt-3 mb-3 align-items-center ">
              <Bullet color={color} />
              <div className=" card-text pl-1 pageClassic">
                {' '}
                <h2 style={h2Style} className="card-text pageClassic">
                  {a_propos.nom}
                </h2>
              </div>
            </div>

            <div className="card-text rounded mb-3 p-2" style={cStyle}>
              {a_propos.contenu}
            </div>
            <div className="mt-2 mb-2 p-2 " style={{ textAlign: 'justify' }}>
              <Markdown>{description}</Markdown>
              <div className="d-flex">
                <ImageGallery images={images} />
              </div>
            </div>
          </div>
          <aside className="col-md-4 col-md-push-8 blog-sidebar order-first order-md-2">
            <div className=" p-0 mb-3 text-md-center">
              <Image
                style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                className="card rounded  box-shadow "
                fluid={image_presentation.childImageSharp.fluid}
                alt={nom}
              />
            </div>
            <div style={descStyle}>
              <strong>Site Web </strong>
              {' '}
              :
              {' '}
              <a style={{ color: 'black' }} href={site_web}>
                {site_web}
              </a>
              {' '}
            </div>
          </aside>
        </div>
      </Layout>
    );
  } catch (error) {
    return <></>;
  }
}

export const query = graphql`
  query($id: String!) {
    allStrapiArtistes(filter: {id: {eq: $id}}) {
      nodes {
        id
        image_presentation {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        a_propos {
          nom
          contenu
        }
        discipline
        description
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
        nom
        site_web
      }
    }
  }
`;
