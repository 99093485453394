import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import Image from 'gatsby-image';

export default class ImageGallerySharp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const images = this.props.images.filter((item) => {
      try {
        if (item.image.hasOwnProperty('publicURL')) {
          return true;
        }
      } catch (error) {
        return false;
      }
    });
    const imageslist = images.map((item, index) => {
      try {
        return (
          <div key={`ig_${index}`} className="card" style={{ margin: '5px' }}>
            <button
              className="link-gallery"
              onClick={() => this.setState({ isOpen: true, photoIndex: index })}
              onKeyDown={() => this.setState({ isOpen: true, photoIndex: index })}
            >
              <Image
                className="card-img"
                style={{ height: '150px', width: '150px' }}
                key={item.image.publicURL}
                fluid={item.image.childImageSharp.fluid}
                alt={item.nom}
              />
            </button>
          </div>
        );
      } catch (error) {
        return <></>;
      }
    });
    const imagesBox = images.map((item) => item.image.publicURL);
    const imageTitles = images.map((item) => item.nom);
    return (
      <>
        {imageslist}

        {isOpen && (
          <Lightbox
            mainSrc={imagesBox[photoIndex]}
            nextSrc={imagesBox[(photoIndex + 1) % imagesBox.length]}
            prevSrc={
              imagesBox[(photoIndex + imagesBox.length - 1) % imagesBox.length]
            }
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() => this.setState({
              photoIndex:
                  (photoIndex + imagesBox.length - 1) % imagesBox.length,
            })}
            onMoveNextRequest={() => this.setState({
              photoIndex: (photoIndex + 1) % imagesBox.length,
            })}
            imageTitle={imageTitles[photoIndex]}
            imageCaption={imageTitles[photoIndex]}
          />
        )}
      </>
    );
  }
}
